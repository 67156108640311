$grey-dk-000: #a084cf;
$grey-dk-100: #2c1853;
$grey-dk-200: #341b63;
$grey-dk-250: #241144;
$grey-dk-300: #160A2C;

$grey-lt-000: #f2edfa;
$grey-lt-100: #D0C4E6;
$grey-lt-200: #D0C4E6;
$grey-lt-300: #D0C4E6;

$blue-000: #b4dcff;
$blue-100: #84c6ff;
$blue-200: #61b4fd;
$blue-300: #40A5FF;

$sidebar-color: $grey-dk-300;
$border-color: $grey-lt-200;

$body-background-color: $grey-lt-000;
$body-text-color: $grey-dk-200;
$body-heading-color: $grey-dk-300;

$nav-child-link-color: #9e8bbb;

$link-color: #5c05df;
$btn-primary-color: $blue-300;
$base-button-color: white;

$search-result-preview-color: $grey-dk-250;
$search-background-color: white;

$code-background-color: $grey-dk-300;
$table-background-color: white;
$feedback-color: lighten($sidebar-color, 10%);

.search-input {
  &::placeholder {
    color: $grey-dk-100;
  }
}

.site-footer {
  a {
    color: $blue-300;
  }
}

nav {
  .nav-list  {
    .nav-list-item {
      .nav-list-expander {
        color: $blue-300;
      }
    }
    .nav-list-link {
      color: $blue-300;

      &.active {
        color: $blue-000 !important;
      }
    }
  }
}

.search-results-list {
  .search-result {
    &:hover, &.active {
      background-color: $grey-lt-000;
    }
  }

  .search-result-title {
    color: $grey-dk-300;
    display: block;
    width: 100%;
  }

  .search-result-previews {
    display: block;
    width: 100%;
    margin-left: 24px;
  }
}

.highlight:not(pre) {
  border: 1px solid $grey-dk-200;
}

.site-header {
  margin-top: 10px;
  border-bottom: none !important;
}

blockquote {
  margin: 0;
  padding: 15px;
  background: #fff8d1;
  border: 1px solid #e0d6f3;
  border-left: 4px solid #ffe664;
  box-shadow: 4px 4px 8px rgba(0,0,0,0.05);

  &.tip {
    background: #dff0ff;
    border-left-color: #a2d3ff;
    color: #31485d;
    font-size: 0.9em;
  }

  &.warning {
    background: #ffe6dc;
    border-left-color: #fdad8e;
    color: #770000;
  }

  p {
    margin: 0;
  }
}

.pink {
  color: #FF457C;
}

.welcome {
  text-align: center;
  background: url('/assets/img/welcome.svg');
  background-size: 100% auto;
  background-position: center bottom;
  background-repeat: no-repeat;
  margin-bottom: 50px;

  .copy {
    background: linear-gradient(rgba(242, 237, 250, 0), rgb(242, 237, 250));
    padding: 49% 10px 5% 10px;
  }

  section {
    max-width: 600px;
    margin: auto;

    p {
      margin-bottom: 20px;
    }
  }
}